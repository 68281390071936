@use '../Style/variables' as v;

.headerTitleLinks {
  color: black;
  font-size: 1em;
}

.headerTitleLinks:hover {
  color: #5d5727;
}

.headerSliderImages {
  object-fit: cover;
  width: 100%;
}

h1 {
  text-decoration: underline;
  font-size: 2em;
}

.headerImageButtons {
  padding: 8px 10px;
  border-radius: 50%;
  font-weight: 800;
  font-size: 2.5em;
  border: none;
  outline: none;
  text-align: center;
  margin: auto;
  background-color: transparent;
  color: white;
}

.headerImageButtons:hover {
  font-size: 2.7em;
  color: white;
}

.headerImageContainer {
  position: relative;
  width: 100vw;
  overflow: hidden;
}

#headerImageLeftButtonDiv {
  padding: 1%;
  left: 5vw;
  margin: 0;
  position: absolute;
  top: 30%;
}

#headerImageRightButtonDiv {
  padding: 1%;
  right: 7vw;
  position: absolute;
  top: 30%;
}

.aboutLink {
  font-size: 1.2em;
  text-decoration: none;
  color: black;
}

.home-page-text {
  font-size: 1.5em;
  padding: 2%;
  padding-right: 5%;
}

.headerImageSliderContainer {
  position: relative;
  overflow: hidden;
  text-align: center;
  padding-top: 500px;
}

.mobileHeader {
  z-index: 900;

}
.menu label {
  cursor: pointer;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #fff;
  border-radius: 50px;
  margin: 0 0.2em 1em;
}
.menu label:hover,
.menu label:focus {
  background: #1c87c9;
}

.headerMenu,
.homepageHeader {
  position: relative;
  top: 0px;
  width: 100%;
  text-align: center;
  z-index: 200;
  padding-bottom: 6%;
}
.homepageHeader {
  position: absolute;
}

.headerMenu,
.stickyHeaderMenu {
  background-color: white;
}

.stickyHeaderMenu {
  top: 0px;
  position: fixed;
  width: 100%;
  text-align: center;
  z-index: 200;
  font-size: 1em;
}

.headerLogo {
  width: 200px;
}

.headerLogoSticky {
  width: 100px;
}

.logoDiv {
  float: left;
  position: absolute;
  top: 0px;
  padding-left: 1%;
}

.homepageMenu {
  display: inline-block;
  margin: 0 auto;
  margin-right: 0%;
  padding: 2%;
}

.homepageMenuSticky {
  display: inline-block;
  margin: 0 auto;
  margin-right: 0%;
  padding: 2%
}

.homepageMenu a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 2em;
}

.homepageMenuSticky a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1.5em;
}

.homepageMenu.responsive {
  position: relative;
}
.homepageMenu.responsive a.icon {
  position: absolute;
  right: 0;
  top: 0;
}

.homepageMenu.responsive a {
  float: none;
  display: block;
  text-align: left;
}

/* Hide the link that should open and close the topnav on small screens */
.headerMenu .icon {
  display: none;
}

.menuDropdown {
  position: absolute;
  top: 60% !important;
}

.menuDropdownSticky,
.menuDropdown {
  width: auto;
  font-size: 1em;
  text-align: left;
}

.menuDropdownSticky {
  top: 70% ;
}

#header-links{
  display: flex;
  justify-content: center;
}

.packages-header-link {
  display: flex;
  align-items: center;
}

#packages-drop-down-menu {
  position: absolute;
  display: flex;
  top: 100%;
  list-style: none;
  background-color: white;
  padding: 10px;
  margin: 0;
  border-radius: 6px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

#mobile-home-page-logo {
  width: 60vw;
  display: block;
  margin: auto;
  padding: 20%;
  position: relative;
} 

.sub-menu-link:hover {
  background-color: #faf9f6;
}

.admin-link {
  margin-left: auto;
}

.centered-links {
  display: flex;
  justify-content: center;
  flex: 1;
}

@media screen and (max-width: 1025px) {
  .homepageMenu a.icon {
    float: right;
    display: block;
  }

  .homepageMenu.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .home-page-text {
    font-size: 1.8em;
    padding: 5%;
  }

  .aboutLink {
    font-size: 1.2em;
  }

  .headerSliderImages {
    object-fit: cover;
    width: 100vw;
  }

  #headerImageLeftButtonDiv {
    top: 40%;
    left: 5vw;
  }

  #headerImageRightButtonDiv {
    top: 40%;
    right: 5vw;
  }
}
