@use '../Style/variables' as v;

.contatiner {
  background-color: #faf9f6;
  text-align: center;
}

.footerButtonDiv {
  margin: auto;
}

#footerButton {
  margin: 5%;
  text-decoration: none;
  font-size: 1.8em;
  color: black;
  font-weight: bold;
}

#footerButton:hover {
  color: white;
}

.socialImages {
  padding: 10px;
  vertical-align: middle;
}

.socialText {
  font-size: 1.5em;
  font-weight: bold;
}

a:hover {
  color: white;
}

.footerInstagramHandle {
  padding-top: 100px;
  font-size: 2.2em;
  color: black;
}

h2,
p {
  text-align: none;
  padding-left: 0%;
}

.bottomDiv {
  height: 75px;
  position: relative;
}

body {
  font-family: pierson;
}

.footerCopyRightText {
  color: black;
  padding-bottom: 20px;
}

#at-symbol{
  font-size: 0.6em;
  display: inline-block;
}

#instagram-handle{
  display: inline-block;
}

.socialLinks {
  display: flex;
  justify-content: center;
  bottom: 0px;
  padding: 100px 0;
  margin: auto;
}

#footer-logo-div{
  max-width: 10vw;
}

@media screen and (max-width: 1025px) {
  p {
    padding: 0%;
    margin: 0%;
  }

  .footerInstagramHandle {
    padding-top: 50px;
    font-size: 1.6em;
  }

  .bottomDiv {
    height: 150px;
  }

  .contatiner {
    padding: 5%;
  }

  .footerCopyRightText {
    font-size: 0.8em;
    left: 0%;
    padding: 4%;
    padding-top: 5%;
  }

  #footer-logo-div{
    max-width: 30vw;
  }
  
}

@media screen and (min-width: 600px) {
  #inline {
    display: inline;
  }
}
