.aboutDiv {
  background-color: yellow;
}

.aboutImage{
  width: 40vw;
  padding: 0 50px;
}

.about-me-page-div{
  padding: 20% 10%;
  display: flex;
  flex-direction: row;
}

.aboutPageAboutChey {
  text-align: justify;
}

@media screen and (max-width: 1025px) {
  .about-me-page-div {
    flex-direction: column;
    padding: 20% 10%;
  }

  .aboutImage{
    width: 100%;
    padding: 0;
  }

  .aboutPageAboutChey {
    padding: 50px 0;
  }
}
