.floatContainer {
  width: 100%;
  padding: 50px 0;
}

.legend {
  display: none;
}

#home-about-image {
  width: 100%;
}

h1 {
  text-decoration: none;
}

.aboutPhotographer {
  text-align: center;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.App {
  overflow: hidden;
}

#floatChild {
  float: left;
}

#home-page-content {
  padding: 10% 10%;
}

.homepageAboutButton {
  padding: 10% 10% 0 0;
  text-align: left;
}

.testHeader {
  padding-top: 10%;
}

.about-div {
  display: flex;
  flex-wrap: wrap;
  padding: 50px
}

.about-div:nth-child(odd) .about-image-div {
  order: 1;
}

.about-div:nth-child(odd) .about-text-div {
  order: 2;
}

.about-div:nth-child(even) .about-image-div {
  order: 2;
}

.about-div:nth-child(even) .about-text-div {
  order: 1;
}

.about-div:nth-child(3) {
  padding-bottom: 10%;
}

.about-image-div, .about-text-div {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.about-image-div img {
  width: 100%;
  height: auto;
}

#about-me-text {
  padding: 70px;
}

.about-text-div a {
  text-decoration-color: #00000099;
}

#sessions-offered-div {
  padding: 10% 0;
}


@media screen and (min-width: 1026px) {
  .floatContainer {
    display: flex;
    justify-content: space-around;
  }

  .photography-services-div{
    flex-basis: 30%;
    flex-grow: 0;
  }

  .home-page-body {
    padding: 0 10%
  }

  .homepage-images {
    max-width: 50vw;
  }
  
}

@media screen and (max-width: 1025px) {
  .floatContainer {
    padding: 20% 0;
    text-align: center;
    margin: 0%;
  }

  .photography-services-div{
    padding: 20% 0;
  }

  .carousel .slide {
    min-width: 100% !important;
  }

  .home-page-body {
    padding-bottom: 10%;
    float: none;
    width: 80vw;
  }

  #floatChild {
    float: none;
    width: 100%;
  }

  #home-page-content {
    padding: 20% 10%;
  }
  
  .testHeader {
    padding-top: 20%;
  }

  .homepage-images {
    max-width: 100vw;
  }

  .about-div {
    flex-direction: column;
    height: auto;
    padding: 50px 0;
  }

  .about-text-div {
    padding: 50px 0;
  }

  .about-text-div, .about-image-div {
    flex: 1 1 auto;
  }

  .about-div:nth-child(odd) .about-image-div {
    order: 1;
  }
  
  .about-div:nth-child(odd) .about-text-div {
    order: 2;
  }
  
  .about-div:nth-child(even) .about-image-div {
    order: 1;
  }
  
  .about-div:nth-child(even) .about-text-div {
    order: 2;
  }

  #about-me-text {
    padding: 0;
  }

  #sessions-offered-div {
    padding: 20% 0;
  }

  .about-div:nth-child(3) {
    padding-bottom: 20%;
  }

}
