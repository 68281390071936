#wedding-packages-header-image{
    width: 100vw;
}

#wedding-packages-div, #life-style-packages-div{
    padding: 10%;
}

#life-style-packages-div {
    flex-wrap: wrap;
    justify-content: center;
}

#lifestyle-packages-header-image {
    width: 100vw;
}

@media only screen and (min-width: 600px) {
    #wedding-packages-div, #life-style-packages-div{
        padding: 5%;
        display: flex;
    }
}