#servicesCheckboxDiv {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.formGroup {
  margin: auto;
  width: 70%;
  padding: 10px;
}

.contactForm {
  padding: 10%;
  width: 40vw;
  margin: auto;
}

.contactMeTitle,
.enquiryTitle,
.extraContactMeText {
  padding-left: 0%;
  font-size: 1.5em;
}

.contactMeTitle {
  padding: 5%;
}

.formCard{
  padding: 10%;
}

.buttonDiv{
  margin-top: 10%;
  padding-top: 10%;
}

.modalText{
  font-size: 1em;
  text-align: center;
}

.modal{
  border-radius: 10px;
  padding: 20px;
}

#extra-contact-information{
  border-top: 1px rgb(118, 118, 118) solid;
  margin-top: 50px;
}

.contact-information {
  display: flex;
  align-items: center;
}

.contact-information-icon {
  padding-right: 10px;
}

#select-service-drop-down {
  text-align: left;
}

.contact-form-submit-button:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1025px) {
  .contactForm {
    width: 80vw;
  }

  .formCard {
    padding: 20px;
  }

.enquiryTitle,
.extraContactMeText {
  text-align: left;
  padding-left: 0%;
  font-size: 1em;
}
}
