#blog-creation-div {
	padding: 10%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.rdw-editor-main {
	box-shadow: inset 1px 1px 3px #999;
}

.create-blog-input {
	padding: 100px;
}

.wrapperClassName {
	margin: 10px 0;
}

.create-blog-editor-div {
	min-height: 60vh;
}

#blog-create-button-div {
	padding-top: 30px;
}

#blog-create-button-div:first-child{
	padding-left: 0 !important;
}

#blog-preview-button {
	padding-left: 0 !important;
}

.blog-create-button {
	flex-grow: 1;
	margin: 0 5px !important;
}

.file-input {
	display: none;
}

.custom-file-label {
	background-color: "white";
	color: #828749;
	border-radius: 4px;
	cursor: pointer;
	border: 1px solid rgba(130, 135, 73, 0.5);
	display: grid;
	justify-content: center;
	align-content: center;
	height: 61.75px;
}

.file-input-container {
	position: relative;
}

/* Optionally style the label when hovering over it */
.custom-file-label:hover {
	background-color: rgba(130, 135, 73, 0.04);
} 

#blog-hero-image-clear-button {
	height: 61.75px;
	width: -webkit-fill-available;
}

@media (max-width: 767px) {
	#blog-create-button-div {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.blog-create-button {
		flex-grow: 1;
		margin: 5px 0 !important;
	}
}
