@use '../Style/variables' as v;

.menu-item {
  font-size: 2em;
  text-align: left;
  text-decoration: none;
  padding-top: 10px;
  color: black;
}

.mobileHeader {
  width: 100% !important;
}

.menu-item:hover {
  color: #5d5727;
}

.mobileHeaderLogo {
  background-color: transparent;
  width: 40px;
  width: 100%;
  z-index: 1000;
  padding-bottom: 10%;
}

.mobileHeaderLogo img {
  display: block;
  margin: auto;
}

.mobileLogo {
  width: 30vw;
  padding-top: 10%;
}

#react-burger-cross-btn {
  width: 100px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: black;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 100px;
  width: 100px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  height: 100vh;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  height: 100%;
  overflow-y: auto;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.packagesSubMenu {
  font-size: 1.3em;
  text-align: left;
  text-decoration: none;
  padding-top: 20px;
  color: black;
}

.subMenuDiv {
  padding-top: 10px;
  height: 100px;
}

#wedding {
  top: 10%;
  position: relative;
}

#lifestyle {
  top: 30%;
  position: relative;
}

.mobile-header-link {
  display: block;
  align-items: center;
  text-decoration: none;
  padding: 30px 0 0 40px;
}

.mobile-header-link-text {
  display: flex;
  align-items: center;
}

.mobile-header-link-icon {
  padding-right: 20px;
  color: #00000099;
}

.mobile-header-copyright-text {
  font-size: 0.8em;
  left: 0%;
  padding: 4%;
  bottom: 0;
}

#mobile-header-social-links {
  padding: 70px 40px 70px 40px;
  bottom: 100px;
}

.socialImages:first-child {
  padding-left: 0;
}

.mobileHeader {
  overflow-y: scroll;
}

.headerMenuDivParent {
  position: relative;
}