#blog-post-container {
	padding: 15% 5%;
}

#blog-search-form-control {
	float: left;
	width: 500px;
	margin: 1rem;
}

@media only screen and (max-width: 768px) {
	#blog-post-container {
		padding-bottom: 25%;
	}

	#blog-search-form-control {
        width: 90%;
    }
}

