.login-with-google-btn-div {
    padding-top: 1rem;
}

.login-page {
    background-color: transparent;
}

#logged-in-div {
    padding: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

#sign-out-button {
    margin: 20px
}