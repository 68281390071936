.contactMePageBG {
  background-image: url("../Images/contact-page-background.jpg");
  background-repeat: no-repeat, repeat;
  background-size: cover;
}

.headerLogoDiv {
  height: 10em;
}

@media screen and (max-width: 1025px) {
  .headerLogoDiv {
    height: auto;
  }
}
