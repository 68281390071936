#blog-post {
	padding: 10%;
}

.blog-author-div {
	display: flex;
	text-align: left;
	align-items: center;
	padding: 50px 0 0 0;
}

.blog-author-name {
	padding-left: 15px;
}

#blog-text-div {
	text-align: left;
	padding-top: 10%;
	color: #00000099 !important;
	font-size: 20px;
	font-family: "Billie";
}

#blog-tag-container-div {
	padding: 10% 0;
	display: flex;
	flex-wrap: wrap;
}

.blog-tag-div {
	background-color: #faf9f6;
	margin: 5px;
	border-radius: 10px;
	padding: 10px;
}

#blog-subtitle-header {
	padding-bottom: 30px;
}

#blog-loading-div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	height: 100px;
	width: 100vw;
}

.blog-back-button {
	padding-left: 10%;
	display: flex;
	align-items: center;
}

.blog-back-button a {
	text-decoration: none !important;
}

.blog-back-link {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 1025px) {
	#blog-tag-container-div {
		padding: 50px 0;
	}

	#blog-post {
		padding: 50px;
	}
  }

