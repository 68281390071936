.package-container {
  padding-bottom: 40px;
  max-width: 500px;
}

.lifestyle-package-container {
  max-width: 500px;
}

.package-container p {
  text-align: center;
}

.package-container h1 {
  text-align: center;
}

.package-container h5 {
  text-align: center;
}

.package-image {
  width: 100%;
}

.package-title {
  text-align: left;
  text-decoration: none;
  font-weight: lighter;
}

.package-coverage {
  text-align: left;
  font-size: 1.3em;
  padding: 20px 0;
}

.package-inclusion-list {
  text-align: left;
  font-size: 1.2em;
  line-height: 2.5;
}

.package-pricing {
  text-align: left;
  font-size: 1.3em;
  padding-right: 20px;
}

.package-pricing-booking-div {
 text-align: center;
 padding: 50px 0 20px 0;
}

.book-package-button {
  font-size: 1.2em;
  text-decoration: none;
  color: black;
}

#life-style-packages, #wedding-package {
  padding-bottom: 10%;
}

.lifestyle-package-container {
  padding-bottom: 40px;
  padding: 40px 20px;
}

.package-inclusion-item {
  padding: 15px;
}

.featured-package-div {
  background-image: url(../Images/featured-package-border-image.png);
  background-repeat: round;
  padding: 100px;
}

.package-info-div {
  padding: 75px;
}

.popular-package-text {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: 50px !important;
}

.package-book-button {
  text-align: center;
}

.package-outlined-button a:hover {
  color: black !important;
}

.package-outlined-button:hover {
  transform: scale(1.1);
}

.package-contained-button:hover {
  transform: scale(1.1);
}

.package-outlined-button {
  border: 1px solid #00000099 !important;
}

.lifestyle-package-container h1 {
  text-align: center;
}

.lifestyle-package-container p {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .package-container {
    padding: 40px 20px;
  }

  .lifestyle-package-container {
    width: 25vw;
  }
  
}

@media only screen and (max-width: 768px) {
  .package-info-div {
    padding: 30px;
    padding-top: 75px;
    padding-bottom: 75px;
  }
}