.admin-content-div {
    padding: 5% 10% 10% 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#admin-user-information {
    padding-left: 50px;
}

#admin-blogs-header {
    padding-top: 100px;
}

#create-blog-button {
    margin-bottom: 100px;
}

#create-blog-button:hover {
    transform: scale(1.1);
}

.blog-table-edit-column:hover {
    cursor: pointer;
}