#preview-blog-div {
    position: fixed;
    background-color: #fff;
    border-left: 1px solid #00000099;
    height: 100vh;
    width: calc(100vw - 5%);
    top: 0;
    right: 0;
    z-index: 1001;
}

#preview-blog-close-button {
    display: flex;
    justify-content: flex-start;
    padding: 20px 20px 50px 20px;
}

#preview-blog-content-div {
    padding: 10%;
}