button:active {
  transform: scale(1.03);
}

#testLeftButtonDiv {
  top: 40%;
}

#testRightButtonDiv {
  top: 40%;
}

.test{
  display:         flex;
  flex-wrap:       wrap;
  justify-content: center;
  padding: 2%;
  padding-bottom: 50px;
}

#imageContainer,
#imageContainer img {
  max-width: 100vw;
  display:         flex;
  flex-wrap:       wrap;
  justify-content: center;
}

.container {
  margin: auto;
}

.innerContainer {
  margin: auto;
  width: 100%;
}

.testomonialButtons {
  padding: 8px 10px;
  border-radius: 50%;
  font-weight: 800;
  font-size: 2em;
  border: none;
  outline: none;
  text-align: center;
  margin: auto;
  color: black;
  background-color: #f2f2ed;
}

.testomonialButtons:hover {
  color: white;
}

.testImageSlider {
  width: 100%;
  margin: auto;
  display: block;
}

@media screen and (min-width: 992px) {
  #imageContainer,
  #imageContainer img {
    height: 90vh;
  }
  .testImageSlider {
    width: 60vw;
    margin: auto;
    display: block;
  }
}
